/* eslint-disable max-len */
import { createIcon } from '../create';

export const IconHamburger = createIcon(({ color, size = 16 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16">
      <g fill={color}>
        <path d="M15,7H1C0.4,7,0,7.4,0,8s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,7,15,7z" />
        <path fill={color} d="M15,1H1C0.4,1,0,1.4,0,2s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,1,15,1z" />
        <path fill={color} d="M15,13H1c-0.6,0-1,0.4-1,1s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,13,15,13z" />
      </g>
    </svg>
  );
});

export const IconDownlaod = createIcon(({ color, size = 16 }) => {
  return (
    <svg width={size} height={size} fill="none">
      <path d="M8 12c.3 0 .5-.1.7-.3L14.4 6 13 4.6l-4 4V0H7v8.6l-4-4L1.6 6l5.7 5.7c.2.2.4.3.7.3Z" fill={color} />
      <path d="M14 14H2v-3H0v4c0 .6.4 1 1 1h14c.6 0 1-.4 1-1v-4h-2v3Z" fill={color} />
    </svg>
  );
});

export const IconPreview = createIcon(({ color, size = 16 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <path
        fill={color}
        d="M8,14c3.6,0,6.4-3.1,7.6-4.9c0.5-0.7,0.5-1.6,0-2.3C14.4,5.1,11.6,2,8,2C4.4,2,1.6,5.1,0.4,6.9 c-0.5,0.7-0.5,1.6,0,2.2C1.6,10.9,4.4,14,8,14z M8,5c1.7,0,3,1.3,3,3s-1.3,3-3,3S5,9.7,5,8S6.3,5,8,5z"
      />
    </svg>
  );
});

export const IconSuccess = createIcon(({ color, size = 16 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16">
      <g fill={color}>
        <path
          fill={color}
          d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M7,11.4L3.6,8L5,6.6l2,2l4-4L12.4,6L7,11.4z"
        />
      </g>
    </svg>
  );
});

export const IconRefresh = createIcon(({ color, size = 16 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16">
      <g fill={color}>
        <path
          d="M14.413.389a.5.5,0,0,0-.849-.235L12.076,1.713a7.494,7.494,0,1,0,3.062,8.595,1,1,0,0,0-1.9-.616,5.513,5.513,0,1,1-2.573-6.5l-1.6,1.68a.5.5,0,0,0,.269.837L15.158,6.8a.478.478,0,0,0,.092.009.5.5,0,0,0,.487-.612Z"
          fill={color}
        />
      </g>
    </svg>
  );
});
