import { SelectInput, colors } from '@newfront-insurance/core-ui';
import { useRouter } from '@newfront-insurance/next-router-provider';
import { useProvider } from '@newfront-insurance/react-provision';
import styled from 'styled-components';
import invariant from 'tiny-invariant';

import { DARK_TEXT } from '@/client/colors';
import { queryClient } from '@/client/providers';
import { AuthProvider, AuthSwapProvider } from '@/client/providers/auth';

interface AccountSelectorProps {
  value: string;
}

export const AccountSelector = ({ value }: AccountSelectorProps): JSX.Element => {
  const { pushRoute } = useRouter();
  const { userDetails: user } = useProvider(AuthProvider);
  const { setSwapContext } = useProvider(AuthSwapProvider);
  invariant(user, 'Not logged in');

  return (
    <AccountSelectorContainer>
      <SelectInput
        value={value}
        onChange={async (uuid) => {
          setSwapContext({ newfrontAccountUuid: uuid });
          queryClient.clear();
          await pushRoute({
            pathname: '/[accountUuid]',
            query: {
              accountUuid: uuid,
            },
          });
        }}
        options={user.accounts.map((account) => ({
          value: account.uuid,
          label: account.name,
        }))}
        style={{ background: colors.steel[100], color: DARK_TEXT, border: colors.border.default }}
      />
    </AccountSelectorContainer>
  );
};

const AccountSelectorContainer = styled.div`
  width: 100%;
`;
