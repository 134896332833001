import { Flexbox, IconManageMember, IconSignOut, Text, colors } from '@newfront-insurance/core-ui';
import classnames from 'classnames';
import styled from 'styled-components';

import { NavigationItemContainer } from '../../navigation-panel/components/navigation-section-list';
import { useManageTeamMemberRoute } from '../lib/use-menu-options';

import { WHITE } from '@/client/colors';
import { useIsMobile } from '@/client/helpers/use-is-mobile';
import { getConfig } from '@/config';

const { LINKS } = getConfig();

interface SideNavigationFooterProps {
  logout: (redirectUrl: string) => Promise<void>;
  accountUuid: string;
}
export const SideNavigationFooter = ({ logout, accountUuid }: SideNavigationFooterProps): JSX.Element => {
  const { link, canManageTeam } = useManageTeamMemberRoute(accountUuid);
  const classes = classnames({
    isActive: link ? link.isActive : '',
  });
  const { isMobile } = useIsMobile();

  return (
    <Flexbox flexDirection="column" gap={10} width="100%">
      {link && canManageTeam && (
        <NavigationItemContainer
          onClick={link.onClick}
          className={classes}
          href={link.href}
          style={{
            padding: isMobile ? '12px' : '0 12px',
          }}
        >
          <IconManageMember size={20} color={link.isActive ? WHITE : colors.steel[200]} />
          Manage team members
        </NavigationItemContainer>
      )}

      <FooterButton onClick={() => logout(LINKS.MARKETING_SITE)} href={LINKS.MARKETING_SITE}>
        <IconSignOut size={20} />
        <Text>Log out</Text>
      </FooterButton>
    </Flexbox>
  );
};

const FooterButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 12px;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;

  &.active {
    color: ${colors.brand[100]};
    background-color: ${colors.brand[400]};
  }
`;
