/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useQuery } from '@newfront-insurance/next-router-provider';
import { useProvider } from '@newfront-insurance/react-provision';
import React, { useState } from 'react';
import invariant from 'tiny-invariant';

import { HeaderMobile } from './header-mobile';
import { SideNavigationFooter } from './side-navigation-footer';
import { SideNavigationHeader } from './side-navigation-header';

import { NavigationPanel, PanelAnchor } from '@/client/components/navigation-panel';
import type { NavigationLink, NavigationSection } from '@/client/components/navigation-panel/lib/types';
import { useIsMobile } from '@/client/helpers/use-is-mobile';
import { AuthProvider } from '@/client/providers/auth';
import type { ServicingType } from '@/client/providers/recent-service-type';

interface Props {
  navigationLinks: NavigationLink[];
  serviceType: ServicingType;
}

export function SideNavigationPanel({ navigationLinks, serviceType }: Props): JSX.Element {
  const accountUuid = useQuery<string>('accountUuid');
  const { userDetails: user, logout } = useProvider(AuthProvider);
  const { isMobile } = useIsMobile();
  const [isCollapsed, onCollapse] = useState(true);

  invariant(user, 'Not logged in');

  const header = (
    <SideNavigationHeader
      accountUuid={accountUuid}
      onCollapse={isMobile ? onCollapse : undefined}
      serviceType={serviceType}
    />
  );
  const contents: NavigationSection[] = [
    {
      id: '1',
      listItems: navigationLinks,
    },
  ];
  const footer = <SideNavigationFooter logout={logout} accountUuid={accountUuid} />;
  // TODO: Pass loading information to the component when connected to the BE
  return isMobile ? (
    <>
      <HeaderMobile menuAction={() => onCollapse(false)} />
      <NavigationPanel
        panelHeader={header}
        mainContent={contents}
        panelFooter={footer}
        isCollapsed={isCollapsed}
        isFloating
        anchor={PanelAnchor.RIGHT}
        onCollapse={onCollapse}
        width="300px"
      />
    </>
  ) : (
    <NavigationPanel panelHeader={header} mainContent={contents} panelFooter={footer} />
  );
}
