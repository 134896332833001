import { isUndefined } from 'lodash';

import { ServicingType } from '@/client/providers/recent-service-type';

export function isDefined<T>(value: T | undefined): value is T {
  return !isUndefined(value);
}

export function isEBAccount(serviceTypes: ServicingType[]): boolean {
  return serviceTypes.indexOf(ServicingType.EMPLOYEE_BENEFITS) !== -1;
}

export function isPnCAccount(serviceTypes: ServicingType[]): boolean {
  return serviceTypes.indexOf(ServicingType.PROPERTY_AND_CASUALTY) !== -1;
}
