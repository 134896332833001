import { Badge, colors, fontFamilies } from '@newfront-insurance/core-ui';
import { useRouter } from '@newfront-insurance/next-router-provider';
import classnames from 'classnames';
import React from 'react';
import styled from 'styled-components';

import type { NavigationLink } from '../../navigation-panel/lib/types';

interface Props {
  tab: NavigationLink;
}

export function TabItem({ tab }: Props): JSX.Element {
  const { route, label, badgeCount, isDisabled } = tab;
  const { createLink } = useRouter();
  const link = isDisabled ? null : createLink(route);

  const className = classnames({
    isActive: link?.isActive,
    isDisabled,
  });

  return link ? (
    <StyledTab
      id={route.pathname}
      role="tab"
      href={link.href}
      aria-selected={link.isActive}
      className={className}
      onClick={link.onClick}
    >
      {label}
      {badgeCount && badgeCount > 0 && (
        <Badge type="info-outline" borderRadiusSize={10} style={{ marginLeft: 8, minWidth: '20px' }}>
          {badgeCount}
        </Badge>
      )}
    </StyledTab>
  ) : (
    <StyledDisabledTab>
      {label}
      {badgeCount && badgeCount > 0 && (
        <Badge type="info-outline" borderRadiusSize={10} style={{ marginLeft: 8, minWidth: '20px' }}>
          {badgeCount}
        </Badge>
      )}
    </StyledDisabledTab>
  );
}

const commonTabStyles = `
font-family: ${fontFamilies.body};
  color: ${colors.steel[400]};
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  padding-bottom: 16px;
  cursor: pointer;
  position: relative;
  display: block;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  &.top-line {
    padding: 16px 24px;
    background: ${colors.steel[400]};
    border: 1px solid #dbdbdb;
    border-bottom: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
    color: #546a83;
  }

  &.top-line::after {
    top: 0px;
    bottom: auto;
  }

  &::after {
    content: '';
    display: block;
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
    background: transparent;
  }

  &.isDisabled {
    opacity: 0.4;
  }
`;

const StyledDisabledTab = styled.div`
  ${commonTabStyles}
`;

const StyledTab = styled.a`
  ${commonTabStyles}
  &.top-line.isActive {
    background: #fff;
    z-index: 5;
  }

  &:hover:not(.isDisabled) {
    color: ${colors.steel[500]};
  }
  &.isActive {
    color: ${colors.steel[500]};
    font-weight: 600;
  }
  &.isActive::after,
  &:hover::after {
    background: ${colors.steel[500]};
    text-decoration: none;
  }
`;
