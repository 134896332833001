import { colors } from '@newfront-insurance/core-ui';
import styled from 'styled-components';

export const PageContainer = styled.div`
  margin: 0 auto;
  padding: 0 24px;
`;

export const HeaderContainer = styled.header`
  border-bottom: 1px solid ${colors.steel[200]}50;
`;

export const UserMenu = styled.nav`
  display: flex;
  align-items: center;
`;

const commonAppLabelLinkStyles = `
display: flex;
text-decoration: none;
border-radius: 4px;
width: 40px;
padding: 10px 0;`;

export const StyledAppLabelLink = styled.a`
  ${commonAppLabelLinkStyles}
  &:hover {
    background-color: ${colors.steel[100]};
  }
`;
export const DisabledAppLabelLink = styled.div`
  ${commonAppLabelLinkStyles}
`;
