import type { Route } from '@newfront-insurance/next-router-provider';
import { isUndefined, omitBy } from 'lodash';

export interface AccountRoute extends Record<string, string | undefined> {
  accountUuid: string;
}

interface PolicyRoute extends AccountRoute {
  policyUuid: string;
}

interface CertificateRoute extends AccountRoute {
  certificateId: string;
}

interface ClaimRoute extends AccountRoute {
  claimUuid: string;
}

interface TaskRoute extends AccountRoute {
  taskUuid: string;
}

export interface InsurableItemsQuery extends Record<string, string | string[] | undefined> {
  accountUuid: string;
  type?: string;
  insurableItemUuid?: string;
}

export function appRoute<Query extends Record<string, string | string[] | undefined>>(pathname: string) {
  return (query?: Query): Route => {
    return {
      pathname,
      query: omitBy(query, (value) => isUndefined(value) || value === '' || value === null),
    };
  };
}
export const baseRoute = '/[accountUuid]';
export const PnCBaseRoute = `${baseRoute}/insurance`;
export const EBBaseRoute = `${baseRoute}/employee-benefits`;
export const pageRoutes = {
  clientHome: appRoute<AccountRoute>(`${PnCBaseRoute}/dashboard`),
  myServiceTeam: appRoute<AccountRoute>(`${baseRoute}/servicing-team`),
  settings: appRoute<AccountRoute>(`${baseRoute}/settings`),
  viewCertificates: appRoute<AccountRoute>(`${PnCBaseRoute}/certificates`),
  viewCertificateDetails: appRoute<CertificateRoute>(`${PnCBaseRoute}/certificates/[certificateId]`),
  viewPolicies: appRoute<AccountRoute>(`${PnCBaseRoute}/policies`),
  viewPolicyDetails: appRoute<PolicyRoute>(`${PnCBaseRoute}/policies/[policyUuid]`),
  viewClaims: appRoute<AccountRoute>(`${PnCBaseRoute}/claims`),
  viewClaimDetails: appRoute<ClaimRoute>(`${PnCBaseRoute}/claims/[claimUuid]`),
  viewAccountInsurableItemsDetails: appRoute<InsurableItemsQuery>(`${PnCBaseRoute}/insurable-items`),
  viewManageTeamMembers: appRoute<AccountRoute>(`${baseRoute}/manage-team-members`),
  viewFiles: appRoute<AccountRoute>(`${PnCBaseRoute}/files`),
  viewBilling: appRoute<AccountRoute>(`${PnCBaseRoute}/billing`),
  viewPlans: appRoute<AccountRoute>(`${EBBaseRoute}/plans`),
  viewVendorSearch: appRoute<AccountRoute>(`${EBBaseRoute}/vendor-search`),
  viewResources: appRoute<AccountRoute>(`${EBBaseRoute}/resources`),
  viewTask: appRoute<TaskRoute>(`${PnCBaseRoute}/tasks/[taskUuid]`),
};

export const newfrontAppRoutes = {
  uploadAttachmentForEntity: '/api-legacy/api/attachments/uploadAttachmentForEntity',
};
