import { Avatar, Flexbox, Text } from '@newfront-insurance/core-ui';
import React from 'react';

import { NFLogo } from '../../core/icons/icon';

import { useIsMobile } from '@/client/helpers/use-is-mobile';

const MOBILE_AVATAR_SIZE = 30;
const DESKTOP_AVATAR_SIZE = 40;

interface UserCardProps {
  firstName?: string;
  lastName?: string;
  email: string;
  imgUrl?: string;
}

export const UserCard = ({ firstName, lastName, email, imgUrl }: UserCardProps): JSX.Element => {
  const { isMobile } = useIsMobile();

  const avatarSize = isMobile ? MOBILE_AVATAR_SIZE : DESKTOP_AVATAR_SIZE;

  return (
    <Flexbox gap={12} alignItems="center">
      <Avatar sizeInPixels={avatarSize} imageUrl={imgUrl ?? NFLogo} />
      <Flexbox
        flexDirection="column"
        justifyContent="center"
        style={{ overflow: 'auto', paddingRight: isMobile ? '24px' : '0' }}
      >
        <Flexbox flexDirection="row" gap={5}>
          {firstName && (
            <Text truncate nowrap>
              {firstName}
            </Text>
          )}
          {lastName && (
            <Text truncate nowrap>
              {lastName}
            </Text>
          )}
        </Flexbox>
        <Text
          size={!firstName && !lastName ? 'regular' : 'small'}
          color={!firstName && !lastName ? 'dark' : 'light'}
          nowrap
          truncate
        >
          {email}
        </Text>
      </Flexbox>
    </Flexbox>
  );
};
