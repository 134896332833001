import {
  IconRibbon,
  IconOrg,
  IconDocument,
  IconHome,
  IconSignOut,
  IconInsepct,
  IconCertificate,
  IconClaim,
  IconBilling,
} from '@newfront-insurance/core-ui';

export enum IconType {
  RIBBON = 'ribbon',
  ORGANIZATION = 'organization',
  DOCUMENT = 'document',
  HOME = 'home',
  SIGN_OUT = 'signout',
  INSPECT = 'inspect',
  CERTIFICATE = 'certificate',
  CLAIM = 'claim',
  BILLING = 'billing',
}

export const NFLogo = '/images/newfront-logo-with-background.png';

interface IconProps {
  type: IconType;
  color?: string;
  size?: 8 | 12 | 14 | 16 | 18 | 20 | 24 | 32 | 40;
}

// Returns the corresponding Icon element based on the id
export const Icon = ({ type, color, size }: IconProps): JSX.Element => {
  switch (type) {
    case IconType.RIBBON:
      return <IconRibbon color={color} size={size} />;
    case IconType.ORGANIZATION:
      return <IconOrg color={color} size={size} />;
    case IconType.DOCUMENT:
      return <IconDocument color={color} size={size} />;
    case IconType.HOME:
      return <IconHome color={color} size={size} />;
    case IconType.SIGN_OUT:
      return <IconSignOut color={color} size={size} />;
    case IconType.INSPECT:
      return <IconInsepct color={color} size={size} />;
    case IconType.CERTIFICATE:
      return <IconCertificate color={color} size={size} />;
    case IconType.CLAIM:
      return <IconClaim color={color} size={size} />;
    case IconType.BILLING:
      return <IconBilling color={color} size={size} />;
    default:
      return <></>;
  }
};
