/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useRouter } from '@newfront-insurance/next-router-provider';
import { createProvider, useProvider } from '@newfront-insurance/react-provision';
import { useEffect } from 'react';
import { useLocalStorage } from 'react-use';

import { AccountDataProvider } from './account-data';

import { EBBaseRoute, PnCBaseRoute } from '@/client/routes';

// TODO: Replace with the enum defined in the BE
export enum ServicingType {
  EMPLOYEE_BENEFITS = 'EMPLOYEE_BENEFITS',
  PROPERTY_AND_CASUALTY = 'PROPERTY_AND_CASUALTY',
}

export const RecentlyViewedServiceTypeProvider = createProvider(async () => {
  const { serviceTypes, defaultServiceType } = useProvider(AccountDataProvider);
  const { router } = useRouter();
  const { route } = router;

  const [recentlyViewedServiceType, setRecentlyViewedServiceType] = useLocalStorage(
    'recent-account-service-type',
    defaultServiceType,
  );

  // This will update the recently viewed service type when the route changes
  useEffect(() => {
    // P&C
    if (route.includes(PnCBaseRoute)) {
      setRecentlyViewedServiceType(ServicingType.PROPERTY_AND_CASUALTY);
    }
    // Employee benefits
    if (route.includes(EBBaseRoute)) {
      setRecentlyViewedServiceType(ServicingType.EMPLOYEE_BENEFITS);
    }
  }, [recentlyViewedServiceType, route, serviceTypes, setRecentlyViewedServiceType]);

  return {
    recentlyViewedServiceType,
  };
});
