import { Flexbox, IconCross, SegmentedControl, colors } from '@newfront-insurance/core-ui';
import { useRouter } from '@newfront-insurance/next-router-provider';
import { useProvider } from '@newfront-insurance/react-provision';
import invariant from 'tiny-invariant';

import { AccountSelector } from '../../core/account-selector';
import { IconButtonRound } from '../../core/icon-button-round';
import { UserCard } from './user-card';

import { AccountDataProvider } from '@/client/providers/account-data';
import { AuthProvider } from '@/client/providers/auth';
import { ServicingType } from '@/client/providers/recent-service-type';
import { PnCBaseRoute, EBBaseRoute } from '@/client/routes';

interface SideNavigationHeaderProps {
  accountUuid: string;
  onCollapse?: (collapsed: boolean) => void;
  serviceType: ServicingType;
}

const accountTypeMap = { PROPERTY_AND_CASUALTY: 'P&C', EMPLOYEE_BENEFITS: 'EB' };

export const SideNavigationHeader = ({
  accountUuid,
  onCollapse,
  serviceType,
}: SideNavigationHeaderProps): JSX.Element => {
  const { userDetails: user } = useProvider(AuthProvider);
  const account = useProvider(AccountDataProvider);
  const { pushRoute } = useRouter();

  invariant(user, 'Not logged in');

  return (
    <Flexbox flexDirection="column" gap={32} width="100%">
      <UserCard firstName={user.firstName} lastName={user.lastName} email={user.email} />
      <Flexbox flexDirection="column" gap={12}>
        <AccountSelector value={accountUuid} />
        {account.serviceTypes.length > 1 && (
          <SegmentedControl
            options={account.serviceTypes.sort().map((type) => {
              return { label: accountTypeMap[type], value: type };
            })}
            value={serviceType}
            onChange={async (value) => {
              await pushRoute({
                pathname: value === ServicingType.PROPERTY_AND_CASUALTY ? PnCBaseRoute : EBBaseRoute,
                query: {
                  accountUuid,
                },
              });
            }}
            fontWeight={400}
            inactiveColor={colors.steel[400]}
            fluid
          />
        )}
      </Flexbox>
      {onCollapse && (
        <div style={{ position: 'fixed', top: 16, right: 16 }}>
          <IconButtonRound onClick={() => onCollapse(true)} width={14} alt="Collapse panel">
            <IconCross strokeWidth={2} size={14} />
          </IconButtonRound>
        </div>
      )}
    </Flexbox>
  );
};
