/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Padding,
  Flexbox,
  FlexCell,
  Avatar,
  colors,
  Portal,
  Tether,
  PopoverAnimation,
  Box,
  Menu2 as Menu,
  Text,
  fontFamilies,
  fontSizes,
  NewfrontLogo,
} from '@newfront-insurance/core-ui';
import { useRouter } from '@newfront-insurance/next-router-provider';
import React from 'react';
import styled from 'styled-components';

import { AccountSelector } from '../../core/account-selector';
import {
  DisabledAppLabelLink,
  HeaderContainer,
  PageContainer,
  StyledAppLabelLink,
  UserMenu,
} from '../lib/common-styled-components';
import type { HeaderProps } from '../lib/types';

export function HeaderDesktop({
  dashboardLink,
  accountUuid,
  user,
  togglePopover,
  targetRef,
  popoverRef,
  isPopoverOpen,
  navigationLinks,
  menuOptions,
}: HeaderProps): JSX.Element {
  const { createLink } = useRouter();

  return (
    <HeaderContainer>
      <PageContainer>
        <Padding y={16}>
          <Flexbox gap={16} alignItems="center">
            {dashboardLink ? (
              <StyledAppLabelLink href={dashboardLink.href} onClick={dashboardLink.onClick} title="Go to dashboard">
                <NewfrontLogo />
              </StyledAppLabelLink>
            ) : (
              <DisabledAppLabelLink>
                <NewfrontLogo />
              </DisabledAppLabelLink>
            )}
            <FlexCell flex={1} alignSelf="center">
              {accountUuid && (
                <DesktopNavigationContainer>
                  {navigationLinks.map((navigationItem) => {
                    const link = createLink(navigationItem.route);
                    return (
                      <LinkContainer
                        data-active={link.isActive}
                        key={navigationItem.route.pathname}
                        onClick={link.onClick}
                        href={link.href}
                      >
                        {navigationItem.label}
                      </LinkContainer>
                    );
                  })}
                </DesktopNavigationContainer>
              )}
            </FlexCell>
            <UserMenu>
              <Flexbox alignItems="center" gap={24}>
                <FlexCell width="250px">{accountUuid && <AccountSelector value={accountUuid} />}</FlexCell>
                <Flexbox flexDirection="column" alignItems="flex-end" style={{ marginRight: 12 }}>
                  {user.firstName ? (
                    <>
                      <Text>{`${user.firstName} ${user.lastName}`}</Text>
                      <Text size="small" color={colors.steel[400]}>
                        {user?.email}
                      </Text>
                    </>
                  ) : (
                    <Text>{user.email}</Text>
                  )}
                </Flexbox>
              </Flexbox>
              <Avatar
                sizeInPixels={32}
                imageUrl={user?.profilePic}
                onClick={togglePopover}
                ref={targetRef}
                initials=""
              />
              <Portal name="popover">
                <Tether targetRef={targetRef} targetAnchor="bottomRight" anchor="topRight" yOffset={10} xOffset={15}>
                  <PopoverAnimation isOpen={isPopoverOpen} direction="up">
                    <Box backgroundColor="white" borderRadius={3} border={1} elementRef={popoverRef} boxShadow="card">
                      <Padding y={8}>
                        <Menu items={menuOptions} />
                      </Padding>
                    </Box>
                  </PopoverAnimation>
                </Tether>
              </Portal>
            </UserMenu>
          </Flexbox>
        </Padding>
      </PageContainer>
    </HeaderContainer>
  );
}

const DesktopNavigationContainer = styled.nav`
  display: flex;
  gap: 2px;
`;

const commonLinkStyles = `
padding: 0 12px;
font-family: ${fontFamilies.body};
font-size: ${fontSizes.regular.fontSize};
text-decoration: none;
color: ${colors.steel[400]};
background: white;
line-height: 32px;
display: block;
position: relative;

&::after {
  content: '';
  display: block;
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: -20px;
  left: 0;
  background: transparent;
  color: red;
}`;

const LinkContainer = styled.a`
  ${commonLinkStyles}

  &:hover {
    background: ${colors.steel[100]};
  }

  &[data-active='true'] {
    color: ${colors.steel[500]};
    font-weight: 600;
  }
  &[data-active='true']::after {
    background: ${colors.steel[500]};
    text-decoration: none;
  }
`;
