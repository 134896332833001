import type { MenuItem2 as MenuItem } from '@newfront-insurance/core-ui';
import { MenuItemType } from '@newfront-insurance/core-ui';
import type { Link } from '@newfront-insurance/next-router-provider';
import { useRouter } from '@newfront-insurance/next-router-provider';

import useIsAdmin from '@/client/helpers/hooks/use-is-admin';
import { pageRoutes } from '@/client/routes';
import { getConfig } from '@/config';

interface Props {
  logout: (redirectUrl: string) => Promise<void>;
  accountUuid: string;
}

const { LINKS } = getConfig();

interface ManageTeamMember {
  link: Link | undefined;
  canManageTeam: boolean;
}

export const useManageTeamMemberRoute = (accountUuid: string): ManageTeamMember => {
  const isAdmin = useIsAdmin();
  const { createLink } = useRouter();

  if (isAdmin && accountUuid) {
    return { link: createLink(pageRoutes.viewManageTeamMembers({ accountUuid })), canManageTeam: true };
  }
  return { link: undefined, canManageTeam: false };
};

export function useMenuOptions({ logout, accountUuid }: Props): MenuItem[] {
  const menuOptions: MenuItem[] = [
    {
      type: MenuItemType.LINK,
      label: 'Log out',
      onClick: () => logout(LINKS.MARKETING_SITE),
    },
  ];

  const { link, canManageTeam } = useManageTeamMemberRoute(accountUuid);
  if (canManageTeam && link) {
    menuOptions.unshift({ type: MenuItemType.DIVIDER });
    menuOptions.unshift({
      type: MenuItemType.LINK,
      label: 'Manage team members',
      href: link.href,
      onClick: link.onClick,
    });
  }
  return menuOptions;
}
