import { Flexbox, Text, colors, fontFamilies, fontSizes } from '@newfront-insurance/core-ui';
import { useRouter } from '@newfront-insurance/next-router-provider';
import classnames from 'classnames';
import React from 'react';
import styled from 'styled-components';

import { Icon } from '../../core/icons/icon';
import type { NavigationLink, NavigationSection } from '../lib/types';

import { WHITE } from '@/client/colors';
import { useIsMobile } from '@/client/helpers/use-is-mobile';

interface NavigationSectionListProps {
  sections: NavigationSection[];
}

interface NavigationSectionItemProps {
  item: NavigationLink;
  isMobile: boolean;
}
const NavigationSectionItem = ({ item, isMobile }: NavigationSectionItemProps): JSX.Element => {
  const { createLink } = useRouter();
  const link = createLink(item.route);
  const classes = classnames({
    isActive: link.isActive,
  });
  return (
    <NavigationItemContainer
      key={item.route.pathname}
      style={{
        padding: isMobile ? '12px' : '0 12px',
      }}
      onClick={link.onClick}
      className={classes}
      href={link.href}
    >
      {item.iconId && <Icon type={item.iconId} color={link.isActive ? WHITE : colors.steel[200]} size={20} />}
      <StyledLinkText>{item.label}</StyledLinkText>
    </NavigationItemContainer>
  );
};

export const NavigationSectionList = ({ sections }: NavigationSectionListProps): JSX.Element => {
  const { isMobile } = useIsMobile();

  return (
    <>
      {sections.map((section) => (
        <Flexbox key={section.id} flexDirection="column" gap={4}>
          {section.title && (
            <Text weight={600} color={colors.steel[400]} key={section.id}>
              {section.title}
            </Text>
          )}
          {section.listItems.map((item) => (
            <NavigationSectionItem key={item.label} item={item} isMobile={isMobile} />
          ))}
        </Flexbox>
      ))}
    </>
  );
};

const StyledLinkText = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
`;

export const NavigationItemContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 0 12px;
  border-radius: 4px;
  text-decoration: none;
  line-height: 32px;
  box-sizing: border-box;
  color: ${colors.steel[500]};
  font-family: ${fontFamilies.body};
  font-size: ${fontSizes.regular.fontSize};

  &.isActive {
    color: ${colors.brand[100]};
    background-color: ${colors.brand[400]};
  }

  &:hover:not(&.isActive) {
    color: ${colors.brand[300]};
    background: ${colors.steel[100]};
  }
`;
