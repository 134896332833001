import { CircleSpinner, Flexbox } from '@newfront-insurance/core-ui';
import type { ReactNode } from 'react';
import React, { Suspense } from 'react';
import styled from 'styled-components';

import { PageErrorBoundary } from '../error-boundary';
import { Header } from './components/header';
import { SideNavigationPanel } from './components/side-navigation-panel';

import type { NavigationLink } from '@/client/components/navigation-panel/lib/types';
import { useIsMobile } from '@/client/helpers/use-is-mobile';
import { FeatureFlags, useFeatureFlag } from '@/client/providers/feature-flag';
import type { ServicingType } from '@/client/providers/recent-service-type';

interface Props {
  children: ReactNode;
  navigationLinks: NavigationLink[];
  serviceType: ServicingType;
}

export function ClientPageLayout({ children, navigationLinks, serviceType }: Props): JSX.Element {
  const { isMobile } = useIsMobile();
  const isSideBarLayoutEnabled = useFeatureFlag(FeatureFlags.SIDE_BAR_LAYOUT, false);

  return (
    <Flexbox flexDirection={isSideBarLayoutEnabled && !isMobile ? 'row' : 'column'} height="100vh" width="100vw">
      {isSideBarLayoutEnabled ? (
        <Suspense fallback={<CircleSpinner />}>
          <SideNavigationPanel navigationLinks={navigationLinks} serviceType={serviceType} />
        </Suspense>
      ) : (
        <Header />
      )}
      <PageContainer>
        <PageErrorBoundary>{children}</PageErrorBoundary>
      </PageContainer>
    </Flexbox>
  );
}

const PageContainer = styled.main`
  margin: 0 auto;
  height: 100%;
  width: 100%;
  overflow: auto;
`;
