import { colors } from '@newfront-insurance/core-ui';
import Image from 'next/image';
import { forwardRef } from 'react';
import styled from 'styled-components';

interface IconButtonGeneralProps {
  alt?: string;
  width?: number;
  height?: number;
  disabled?: boolean;
  onClick?: () => void;
}
interface IconButtonWithSrc extends IconButtonGeneralProps {
  src: string;
  children?: never;
}
interface IconButtonWithComponent extends IconButtonGeneralProps {
  src?: never;
  children: React.ReactNode;
}

type Props = IconButtonWithSrc | IconButtonWithComponent;

export const IconButtonRound = forwardRef<HTMLButtonElement, Props>((props, ref): JSX.Element => {
  const { src, children, width = 40, height = 40, alt, onClick, disabled = false } = props;
  return src ? (
    <StyledButton onClick={onClick} ref={ref} type="button" disabled={disabled}>
      <Image src={src} width={width} height={height} alt={alt} />
    </StyledButton>
  ) : (
    <StyledButton onClick={onClick} ref={ref} type="button" disabled={disabled}>
      {children}
    </StyledButton>
  );
});

const StyledButton = styled.button<{ disabled: boolean }>`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  color: ${colors.steel[500]};
  padding: 0;
  border: 0;
  background: none;
  transition: background 0.3s ease, color 0.3s ease;
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};

  :hover,
  &.isActive {
    background: ${colors.glitter[100]};
    color: ${colors.steel[300]};
  }

  :focus {
    outline: none;
  }
`;
