import type { ClientAccessFeatures } from '@newfront-insurance/account-api';

import type { NavigationLink } from '../../navigation-panel/lib/types';
import { AccountLink } from './account-link';

import useCanViewClaims from '@/client/helpers/hooks/use-can-view-claims';
import { FeatureFlags, useFeatureFlag } from '@/client/providers/feature-flag';

const getPnCNavigationLinks = (
  accountUuid: string,
  showClaimsPage: boolean,
  isDocumentsEnabled: boolean,
): NavigationLink[] => {
  const linkGenerator = new AccountLink(accountUuid);
  const links = [linkGenerator.getHome(), linkGenerator.getPolicy(), linkGenerator.getCertificate()];
  if (showClaimsPage) {
    links.push(linkGenerator.getClaims());
  }

  if (isDocumentsEnabled) {
    links.push(linkGenerator.getFiles());
  }

  links.push(linkGenerator.getInsurableItems(), linkGenerator.getBilling());
  return links;
};

const getEBNavigationLinks = (accountUuid: string): NavigationLink[] => {
  const linkGenerator = new AccountLink(accountUuid);

  const links = [linkGenerator.getBenefits(), linkGenerator.getVendorSearch(), linkGenerator.getResources()];
  return links;
};

interface NavigationLinkProps {
  accountUuid: string;
  features: ClientAccessFeatures;
  isEBAccount: boolean;
  canAccessClaims: boolean;
  canAccessDocuments: boolean;
}

export function getNavigationLinks({
  accountUuid,
  features,
  isEBAccount = false,
  canAccessClaims,
  canAccessDocuments,
}: NavigationLinkProps): NavigationLink[] {
  const showClaimsPage = features.claims && canAccessClaims;
  const links = isEBAccount
    ? getEBNavigationLinks(accountUuid)
    : getPnCNavigationLinks(accountUuid, showClaimsPage, canAccessDocuments);
  return links;
}

export function useNavigationLinks(
  accountUuid: string,
  features: ClientAccessFeatures,
  isEBAccount = false,
): NavigationLink[] {
  // TODO Remove once released https://newfront.atlassian.net/browse/CLIENT-955
  const canAccessDocuments = useFeatureFlag(FeatureFlags.DOCUMENTS, false);
  const canAccessClaims = useCanViewClaims();

  return getNavigationLinks({
    accountUuid,
    features,
    isEBAccount,
    canAccessClaims,
    canAccessDocuments,
  });
}
