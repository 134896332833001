import {
  Spacing,
  Padding,
  Flexbox,
  NewfrontLogo,
  FlexCell,
  Portal,
  Tether,
  PopoverAnimation,
  Box,
  Menu2 as Menu,
  Text,
} from '@newfront-insurance/core-ui';
import { useQuery } from '@newfront-insurance/next-router-provider';
import { useProvider } from '@newfront-insurance/react-provision';
import type { RefObject } from 'react';
import React from 'react';
import styled from 'styled-components';

import { AccountSelector } from '../../core/account-selector/index';
import { IconButtonRound } from '../../core/icon-button-round';
import { IconHamburger } from '../../core/icons/16';
import {
  DisabledAppLabelLink,
  HeaderContainer,
  PageContainer,
  StyledAppLabelLink,
  UserMenu,
} from '../lib/common-styled-components';
import type { PopoverMenuOptions, MobileHeaderProps } from '../lib/types';
import { TabList } from './tablist';

import { MOBILE_BREAKPOINT } from '@/client/helpers/constants';
import { AccountDataProvider } from '@/client/providers/account-data';

interface PopoverMenuProps {
  options: PopoverMenuOptions;
  togglePopover: () => void;
}

function PopoverMenu({ options, togglePopover }: PopoverMenuProps): JSX.Element {
  const { targetRef, popoverRef, isPopoverOpen, menuOptions } = options;
  return (
    <UserMenu>
      <IconButtonRound
        onClick={togglePopover}
        ref={targetRef as unknown as RefObject<HTMLButtonElement>}
        width={18}
        height={12}
        alt="Menu Icon"
      >
        <IconHamburger />
      </IconButtonRound>
      <Portal name="popover">
        <Tether targetRef={targetRef} targetAnchor="bottomRight" anchor="topRight" yOffset={10} xOffset={15}>
          <PopoverAnimation isOpen={isPopoverOpen} direction="up">
            <Box backgroundColor="white" borderRadius={3} border={1} elementRef={popoverRef} boxShadow="card">
              <Padding y={8}>
                <Menu items={menuOptions} />
              </Padding>
            </Box>
          </PopoverAnimation>
        </Tether>
      </Portal>
    </UserMenu>
  );
}

export function HeaderMobile({
  dashboardLink,
  showAccountSelector,
  menuPopover,
  menuAction,
  navigationLinks,
}: MobileHeaderProps): JSX.Element {
  const accountUuid = useQuery<string>('accountUuid');
  const { name } = useProvider(AccountDataProvider);

  return (
    <HeaderContainer>
      <PageContainer>
        <Padding y={16}>
          <Flexbox gap={16} alignItems="center">
            {dashboardLink ? (
              <StyledAppLabelLink href={dashboardLink.href} onClick={dashboardLink.onClick} title="Go to dashboard">
                <NewfrontLogo />
              </StyledAppLabelLink>
            ) : (
              <DisabledAppLabelLink>
                <NewfrontLogo />
              </DisabledAppLabelLink>
            )}
            <FlexCell flex={1}>
              {showAccountSelector ? <AccountSelector value={accountUuid} /> : <Text>{name}</Text>}
            </FlexCell>
            {menuPopover ? (
              <PopoverMenu options={menuPopover} togglePopover={menuAction} />
            ) : (
              <IconButtonRound onClick={menuAction} width={18} height={12} alt="Menu Icon">
                <IconHamburger />
              </IconButtonRound>
            )}
          </Flexbox>
        </Padding>
      </PageContainer>
      {navigationLinks && (
        <>
          <Spacing height={24} />
          <MobileNavigationContainer>
            <NavigationScroller>
              <TabList tabs={navigationLinks} />
            </NavigationScroller>
          </MobileNavigationContainer>
        </>
      )}
    </HeaderContainer>
  );
}

const MobileNavigationContainer = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-height: none;
  max-width: 1080px;
  margin: 0 auto;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const NavigationScroller = styled.div`
  padding: 0 24px;
  width: fit-content;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0;
  }
`;
