/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type { Account } from '@newfront-insurance/account-api';
import { NotFoundError } from '@newfront-insurance/data-layer-client';
import { useQuery as useRouteQuery } from '@newfront-insurance/next-router-provider';
import { createProvider, useProvider } from '@newfront-insurance/react-provision';
import invariant from 'tiny-invariant';

import type { ServicingType } from './recent-service-type';
import { TRPCProvider } from './trpc';

// TODO: Remove the type casting once the data is coming from the BE
export const AccountDataProvider = createProvider(
  (): Account & { serviceTypes: ServicingType[]; defaultServiceType: ServicingType } => {
    const accountUuid = useRouteQuery<string>('accountUuid');
    const { useQuery } = useProvider(TRPCProvider);

    const { data, error } = useQuery(['account.getAccount', { accountUuid }], {
      suspense: true,
    });

    // Data is always loaded with suspesne
    invariant(data, 'Data not loaded');

    // If there's ever an error loading the client, it should just halt the whole page.
    if (error) {
      throw error;
    }

    // All accounts should have a service type
    if (data.serviceType.length === 0) {
      throw new NotFoundError('No service types found for account');
    }

    return { ...data.accountResult, serviceTypes: data.serviceType, defaultServiceType: data.serviceType[0] };
  },
);
