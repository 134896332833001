import { IconType } from '../../core/icons/icon';
import type { NavigationLink } from '../../navigation-panel/lib/types';

import { baseRoute, PnCBaseRoute, EBBaseRoute, pageRoutes } from '@/client/routes';

export class AccountLink {
  private readonly accountUuid;

  private readonly isDisabled;

  constructor(accountUuid: string, isDisabled = false) {
    this.accountUuid = accountUuid;
    this.isDisabled = isDisabled;
  }

  public getBaseRoute = (): NavigationLink => {
    return {
      label: 'Home',
      route: {
        pathname: baseRoute,
        query: {
          accountUuid: this.accountUuid,
        },
      },
      iconId: IconType.HOME,
      isDisabled: this.isDisabled,
    };
  };

  public getEBBaseRoute = (): NavigationLink => {
    return {
      label: 'EB Home',
      route: {
        pathname: EBBaseRoute,
        query: {
          accountUuid: this.accountUuid,
        },
      },
      iconId: IconType.HOME,
      isDisabled: this.isDisabled,
    };
  };

  public getPnCBaseRoute = (): NavigationLink => {
    return {
      label: 'P&C Home',
      route: {
        pathname: PnCBaseRoute,
        query: {
          accountUuid: this.accountUuid,
        },
      },
      iconId: IconType.HOME,
      isDisabled: this.isDisabled,
    };
  };

  public getHome = (): NavigationLink => {
    return {
      label: 'Home',
      route: pageRoutes.clientHome({ accountUuid: this.accountUuid }),
      iconId: IconType.HOME,
      isDisabled: this.isDisabled,
    };
  };

  public getPolicy = (): NavigationLink => {
    return {
      label: 'Policies',
      route: pageRoutes.viewPolicies({ accountUuid: this.accountUuid }),
      iconId: IconType.INSPECT,
      isDisabled: this.isDisabled,
    };
  };

  public getCertificate = (): NavigationLink => {
    return {
      label: 'Certificates',
      route: pageRoutes.viewCertificates({ accountUuid: this.accountUuid }),
      iconId: IconType.CERTIFICATE,
      isDisabled: this.isDisabled,
    };
  };

  public getClaims = (): NavigationLink => {
    return {
      label: 'Claims',
      route: pageRoutes.viewClaims({ accountUuid: this.accountUuid }),
      iconId: IconType.CLAIM,
      isDisabled: this.isDisabled,
    };
  };

  public getFiles = (): NavigationLink => {
    return {
      label: 'Files',
      route: pageRoutes.viewFiles({ accountUuid: this.accountUuid }),
      iconId: IconType.DOCUMENT,
      isDisabled: this.isDisabled,
    };
  };

  public getInsurableItems = (): NavigationLink => {
    return {
      label: 'Insurable items',
      route: pageRoutes.viewAccountInsurableItemsDetails({ accountUuid: this.accountUuid }),
      iconId: IconType.RIBBON,
      isDisabled: this.isDisabled,
    };
  };

  public getBilling = (): NavigationLink => {
    return {
      label: 'Billing',
      route: pageRoutes.viewBilling({ accountUuid: this.accountUuid }),
      iconId: IconType.BILLING,
      isDisabled: this.isDisabled,
    };
  };

  public getBenefits = (): NavigationLink => {
    return {
      label: 'Your benefits',
      route: pageRoutes.viewPlans({ accountUuid: this.accountUuid }),
      iconId: IconType.RIBBON,
      isDisabled: this.isDisabled,
    };
  };

  public getVendorSearch = (): NavigationLink => {
    return {
      label: 'Vendor search',
      route: pageRoutes.viewVendorSearch({ accountUuid: this.accountUuid }),
      iconId: IconType.ORGANIZATION,
      isDisabled: this.isDisabled,
    };
  };

  public getResources = (): NavigationLink => {
    return {
      label: 'Resources',
      route: pageRoutes.viewResources({ accountUuid: this.accountUuid }),
      iconId: IconType.DOCUMENT,
      isDisabled: this.isDisabled,
    };
  };
}
