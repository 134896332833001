import { Flexbox, colors, CircleSpinnerInline } from '@newfront-insurance/core-ui';
import classnames from 'classnames';
import React from 'react';
import styled, { keyframes } from 'styled-components';

import { NavigationSectionList } from './components/navigation-section-list';
import type { NavigationSection } from './lib/types';

import { OVERLAY_BACKGROUND } from '@/client/colors';
import { useIsMobile } from '@/client/helpers/use-is-mobile';

export enum PanelAnchor {
  LEFT = 'left',
  RIGHT = 'right',
}

interface NavigationPanelProps {
  mainContent: NavigationSection[];
  width?: string;
  panelHeader?: JSX.Element;
  panelFooter?: JSX.Element;
  isLoading?: boolean;
  isCollapsed?: boolean;
  isFloating?: boolean;
  anchor?: PanelAnchor;
  onCollapse?: (isCollapsed: boolean) => void;
}

export const NavigationPanel = ({
  mainContent,
  width = '285px',
  panelHeader,
  panelFooter,
  isLoading,
  isCollapsed = false,
  isFloating = false,
  anchor = PanelAnchor.LEFT,
  onCollapse,
}: NavigationPanelProps): JSX.Element => {
  const { isMobile } = useIsMobile();
  const classes = classnames({
    isCollapsed,
    isFloating,
  });
  return (
    <>
      <NavigationPanelContainer style={{ width }} className={classes} data-anchor={anchor}>
        {isLoading ? (
          <Flexbox
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding={isMobile ? '28px 12px' : '28px 20px'}
            width="100%"
            style={{ border: `1px solid ${colors.border.default}`, backgroundColor: 'white' }}
          >
            <CircleSpinnerInline label="Loading..." />
          </Flexbox>
        ) : (
          <Flexbox
            flexDirection="column"
            justifyContent="space-between"
            padding={isMobile ? '28px 12px' : '28px 20px'}
            width="100%"
            gap={32}
            style={{ border: `1px solid ${colors.border.default}`, backgroundColor: 'white', overflow: 'hidden' }}
          >
            <Flexbox padding="8px" paddingRight="0">
              {panelHeader}
            </Flexbox>
            <Flexbox flexGrow={1} flexDirection="column" gap={30}>
              <NavigationSectionList sections={mainContent} />
            </Flexbox>
            <Flexbox alignItems="flex-end">{panelFooter}</Flexbox>
          </Flexbox>
        )}
      </NavigationPanelContainer>
      {!isCollapsed && isFloating && <OverlayMask onClick={() => (onCollapse ? onCollapse(true) : undefined)} />}
    </>
  );
};

const overlayAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const OverlayMask = styled.div`
  background-color: ${OVERLAY_BACKGROUND};
  animation: ${overlayAnimation} 0.3s ease;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 800;
`;

const NavigationPanelContainer = styled.div<{
  width?: string;
  isCollapsed?: boolean;
  isFloating?: boolean;
  anchor?: string;
}>`
  display: flex;
  flex: 0;
  height: 100%;
  transition: all 0.3s ease;

  &.isCollapsed {
    min-width: 0;
  }

  &.isFloating {
    z-index: 900;
    position: fixed;
    top: 0;
  }

  &[data-anchor='right'] {
    right: 0;
    &.isCollapsed {
      transform: translateX(100%);
    }
  }

  &[data-anchor='left'] {
    left: 0;
    &.isCollapsed {
      transform: translateX(-100%);
    }
  }
`;
