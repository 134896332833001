import { Spinner } from '@newfront-insurance/core-ui';
import { useQuery } from '@newfront-insurance/next-router-provider';
import { useProvider } from '@newfront-insurance/react-provision';
import React, { useEffect } from 'react';

import { useRecentlyViewedAccount } from '../helpers/recently-viewed-account';
import { AuthSwapProvider } from './auth';

interface AccountContextProviderProps {
  children: React.ReactNode;
}

/**
 * This provider keeps track of the current account. It will save the recently viewed
 * account to localStorage and it will set the auth swap context, which triggers
 * the token to be reloaded.
 */
export function AccountContextProvider({ children }: AccountContextProviderProps): JSX.Element {
  const accountUuid = useQuery<string>('accountUuid');
  const { setSwapContext, swapContext } = useProvider(AuthSwapProvider);
  const [recentlyViewedAccount, setRecentlyViewedAccountUuid] = useRecentlyViewedAccount();

  useEffect(() => {
    if (!recentlyViewedAccount || recentlyViewedAccount !== accountUuid || !swapContext) {
      setSwapContext({ newfrontAccountUuid: accountUuid });
      setRecentlyViewedAccountUuid(accountUuid);
    }
  }, [accountUuid, setRecentlyViewedAccountUuid, setSwapContext, recentlyViewedAccount, swapContext]);

  if (!swapContext) {
    return <Spinner />;
  }

  return <>{children}</>;
}
