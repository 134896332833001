/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { usePopover } from '@newfront-insurance/core-ui';
import { useQuery, useRouter } from '@newfront-insurance/next-router-provider';
import { useProvider } from '@newfront-insurance/react-provision';
import React from 'react';
import invariant from 'tiny-invariant';

import { AccountDataProvider } from '../../../providers/account-data';
import { useMenuOptions } from '../lib/use-menu-options';
import { useNavigationLinks } from '../lib/use-navigation-links';
import { HeaderDesktop } from './header-desktop';
import { HeaderMobile } from './header-mobile';

import { useIsMobile } from '@/client/helpers/use-is-mobile';
import { AuthProvider } from '@/client/providers/auth';
import { pageRoutes } from '@/client/routes';

export function Header(): JSX.Element {
  const { isMobile } = useIsMobile();
  const { createLink } = useRouter();
  const accountUuid = useQuery<string>('accountUuid');

  const dashboardLink = accountUuid ? createLink(pageRoutes.clientHome({ accountUuid })) : undefined;

  const { clientAccessFeatures } = useProvider(AccountDataProvider);
  const navigationLinks = useNavigationLinks(accountUuid!, clientAccessFeatures);
  const { userDetails: user, logout } = useProvider(AuthProvider);
  const menuOptions = useMenuOptions({ logout, accountUuid });

  const { popoverRef, isPopoverOpen, togglePopover, targetRef } = usePopover<HTMLDivElement, HTMLDivElement>({
    excludeTarget: true,
  });

  invariant(user, 'Not logged in');

  return isMobile ? (
    <HeaderMobile
      dashboardLink={dashboardLink}
      showAccountSelector
      menuPopover={{ targetRef, popoverRef, isPopoverOpen, menuOptions }}
      menuAction={togglePopover}
      navigationLinks={navigationLinks}
    />
  ) : (
    <HeaderDesktop
      dashboardLink={dashboardLink}
      accountUuid={accountUuid}
      user={user}
      togglePopover={togglePopover}
      targetRef={targetRef}
      popoverRef={popoverRef}
      isPopoverOpen={isPopoverOpen}
      logout={logout}
      navigationLinks={navigationLinks}
      menuOptions={menuOptions}
    />
  );
}
