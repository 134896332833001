import { Stack } from '@newfront-insurance/core-ui';
import React from 'react';
import styled from 'styled-components';

import type { NavigationLink } from '../../navigation-panel/lib/types';
import { TabItem } from './tab';

interface Props {
  tabs: NavigationLink[];
}

export function TabList({ tabs }: Props): JSX.Element {
  return (
    <StyledNav>
      <Stack gap={24} direction="horizontal" alignItems="center" justifyContent="flex-start">
        {tabs.map((tab) => {
          return <TabItem key={tab.route.pathname} tab={tab} />;
        })}
      </Stack>
    </StyledNav>
  );
}

const StyledNav = styled.nav`
  position: relative;
`;
