/* eslint-disable max-len */
import type { FunctionComponent } from 'react';

export interface IconProps {
  color?: string;
  size?: number;
}

export function createIcon(render: (props: IconProps) => JSX.Element): FunctionComponent<IconProps> {
  function Icon({ color = 'currentColor', size }: IconProps): JSX.Element {
    return render({ color, size });
  }
  return Icon;
}
